.copy-foot{
    background: #FFFFFF;
    padding: 20px 0;
    *{
        color: #312A33;
        font-size: 12px;
        margin: 0;
    }
    .row{
        align-items: center;
    }
}
.social-list.f-wrp ul {
    display: flex;
    padding-left: 25px;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0;
}

.social-list.f-wrp ul img {
    width: 25px;
    display: block;
}
.copy-txt p{
    a{
        text-decoration: none;
        color: inherit;
    }
}

@media (max-width: 767px) {
    .copy-foot{
        padding-top: 25px;
    }
    .copy-txt p{
        text-align: center;
        a{
            text-decoration: none;
            color: inherit;
        }
    }
    .social-list.f-wrp{
        padding: 25px 0;
    }
    .social-list.f-wrp ul{
        justify-content: center;
    }
}