.custom-header {
    &.header-main-wrp {
        padding: 20px 0 15px;
        background-color: #8F7C94;

        .head-con-sec {
            position: relative;
            top: 0;
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}


.home-space-intro-wrapper {
    background: #ebe8ec;

    .home-space-intro.f-wrp {
        display: flex;
        align-items: center;
        padding-top: 35px;
        @media (max-width: 991px) {
            &{
                flex-direction: column;
            }
        }
        .homeSpace-img-sec {
            flex: 1;

            h1 {
                font-family: 'Josefin Sans', sans-serif;
                font-size: 72px;
                font-style: normal;
                font-weight: 300;
                letter-spacing: 3px;
                margin: 0;
                line-height: 1;
                padding-bottom: 30px;
                position: relative;
                z-index: 9;
                @media (max-width: 767px) {
                    &{
                        font-size: 48px;
                        padding-bottom: 0;
                    }
                }
            }

            &>.row {
                width: calc(100% - 35px);
                position: relative;

                &::after {
                    content: '';
                    width: calc(100% + 25px);
                    height: calc(100% - 15px);
                    position: absolute;
                    right: -16px;
                    bottom: 10px;
                    background-image: url('../../assets/images/homeSpaceOrganizing/layout.png');
                    background-position: center;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    @media (max-width: 767px) {
                        &{
                            display: none;
                        }
                    }
                }
            }

            .row {
                margin: 0px;
                align-items: flex-end;

                p {
                    font-size: 15px;
                    padding-right: 15px;
                    position: relative;
                    z-index: 9;
                    margin-bottom: 24px;
                }
            }
            .mob-p{
                @media (max-width: 767px) {
                    &{
                        display: block !important;
                        padding-left: 0;
                    }
                }
            }
            .pc-p{
                @media (max-width: 767px) {
                    &{
                        display: none;
                    }
                }
            }
            @media (max-width: 576px) {
                img{
                    display: none;
                }
            }
        }

        .homeSpace-con-sec {
            flex: 0.6;
            @media (max-width: 1400px) {
                &{
                    flex: 0.3;
                }
            }
            @media (max-width: 991px) {
                &{
                    flex: 1;
                }
            }


            .homeSpace-con-box {
                max-width: 400px;
                margin: 0 auto;
                float: none;
                @media (max-width: 991px) {
                    &{
                        max-width: 100%;
                    }
                }
                p {
                    b {
                        font-weight: 600;
                    }
                }
            }
        }

        span {
            width: 100%;
            display: block;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: bottom;
            }
        }
    }
}

.slider-gallery-wrp {
    background-color: #ebe8ec;
    padding: 100px 0;

    .slider-gallery-sec {
        display: flex;
        gap: 10px;

        .slider-gallery-box {
            width: 200px;
            height: 200px;
            display: block;
            background: #A295A5;
            padding: 20px;

            p {
                color: #fff;
                margin: 0;
            }
        }

        .service-slider-box {
            height: 200px;
            width: calc(100% - 190px);

            .img-gallery-wrp {
                display: flex;
                flex-wrap: nowrap;
                overflow-y: auto;
                height: 200px;
                gap: 15px;

                &::-webkit-scrollbar {
                    width: 5px;
                    height: 5px;
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px transparent;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #A295A5;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: #A295A5;
                }

                span {
                    width: 200px;
                    min-width: 200px;
                    cursor: pointer;
                }
            }
        }
    }
}

.service-list-wrapper {
    background-color: #ebe8ec;

    &::before {
        content: '';
        position: absolute;
        top: 35.5%;
        height: 50px;
        background: #CAC3CC;
        width: 60%;
        left: 0;
    }
    .row {
        @media (max-width: 767px) {
            &{
                margin: 0;
            }
        }
    }

    .service-list-img {
        height: 100%;
        display: block;
        @media (max-width: 991px) {
            &{
                img{
                    object-fit: contain;
                }
            }
        }
        // img{
        //     height: 90%;
        //     position: relative;
        //     top: 10%;
        // }
    }

    .each-list-box {
        background: #CAC3CC;
        border-radius: 4px;
        padding: 25px;
        height: 100%;

        &>.row {
            margin-left: 0;
            @media (max-width: 767px) {
                &{
                    margin: 0;
                }
            }
        }

        h3 {
            margin-top: 0;
            color: #564959;
            font-weight: 600;
        }

        ul {

            li {
                position: relative;
                padding-left: 35px;

                &::before {
                    content: '';
                    position: absolute;
                    left: -5px;
                    top: 0;
                    background-image: url('../../assets/images/homeSpaceOrganizing/list.png');
                    width: 20px;
                    height: 20px;
                    display: block;
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                p {
                    color: #312A33;
                    margin: 0;
                    font-size: 14px;

                }

                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
        }

    }
}

.contact-main-wrp {
    background-color: #ebe8ec;

    .contact-main-box {
        background: #A295A5;
        text-align: center;
        padding: 30px;

        h3 {
            color: #564959;
            font-family: 'Josefin Sans', sans-serif;
            font-weight: 600;
            margin-top: 0;
        }

        button {
            margin-top: 0;

        }
    }
}

.header-main-wrp.custom-header {
    &+.content+.copy-foot {
        background: #EBE8EC;
    }
}


.interior-styling-wrp {
    background: #ebe8ec;

    .interior-home-banner {
        padding-top: 25px;
        padding-bottom: 25px;

        span {
            position: relative;

            .banner {
                padding-top: 20px;
            }

            .bannerBorder {
                position: absolute;
                z-index: 1;
                left: 0;
                top: 0;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }

    .interior-content-wrapper {
        padding: 25px 0;

        .interior-content-box {
            p {
                font-size: 16px;
                font-weight: 300;
                color: #312A33;

                b {
                    font-weight: 600;
                }
            }

        }
    }

    .service-list-wrapper {
        &::before {
            display: none;
            content: "";

        }

        @media (max-width: 767px) {
            &{
                margin: 0;
            }
        }

        .each-service-list {

            .service-list-img {
                position: relative;

                img {
                    position: relative;
                    z-index: 9;
                    width: 100%;
                    margin: 0 auto;
                    overflow: visible;
                }

                &::before {
                    content: "";
                    position: absolute;
                    bottom: 75px;
                    height: 50px;
                    background: #CAC3CC;
                    width: 100%;
                    left: 0;
                }
            }
        }

        .each-list-box {
            padding-left: 75px;
            padding-top: 50px;
            @media (max-width: 991px) {
                &{
                    padding-left: 25px;
                    padding-top: 25px;
                }
            }
        }
    }

    .lamb-light {
        position: relative;
        height: 90%;
        display: block;
        @media (max-width: 767px) {
            &{
                display: none;
            }
        }
        &::before {
            content: "";
            position: absolute;
            top: 0px;
            height: 85px;
            background: #CAC3CC;
            width: 100px;
            left: -5px;
            right: 0;
            margin: 0 auto;
        }

        img {
            position: relative;
            z-index: 1;
            object-fit: contain;
            object-position: top;
        }
    }

    .slider-gallery-wrp {
        padding: 50px 0;

        .service-slider-box {
            .img-gallery-wrp {
                display: flex;
                flex-wrap: nowrap;
                overflow-y: auto;
                height: 200px;
                gap: 15px;

                &::-webkit-scrollbar {
                    width: 5px;
                    height: 5px;
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px transparent;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #A295A5;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: #A295A5;
                }

                span {
                    width: 200px;
                    min-width: 200px;
                    cursor: pointer;
                }
            }
        }
    }

}
