.portfolio-main-wrp {
    background: #EBE8EC;

    .portfolio-header-wrp {
        padding-top: 35px;
        margin-bottom: 40px;
        @media (max-width: 767px) {
            &{
                margin-bottom: 10px;
            }
        }
        .portfolio-header-sec {
            position: relative;

            &::after {
                content: '';
                background-image: url('../../assets/images/portfolioHeader.png');
                background-position: center;
                background-repeat: no-repeat;
                display: block;
                width: 100%;
                height: 30px;
                background-size: contain;
            }

            h1 {
                text-align: center;
                font-family: "Josefin Sans", sans-serif;
                font-size: 72px;
                font-style: normal;
                font-weight: 300;
                letter-spacing: 2px;
                margin: 0;
                line-height: 1;
                padding-bottom: 0;
                position: relative;
                z-index: 9;
                @media (max-width: 567px) {
                    &{
                        font-size: 46px;
                    }
                }
            }
        }
    }

    .portfolio-grd-wrp {
        .portfolio-grid-header {
            display: flex;
            justify-content: space-between;
            @media (max-width: 767px) {
                &{
                    flex-direction: column;
                    text-align: center;
                }
            }

            .discription {
                max-width: 500px;
                text-align: right;
                @media (max-width: 767px) {
                    &{
                        text-align: center;
                    }
                }
            }
        }

        .each-folio-content {
            background-color: #ebe8ec;
            padding: 100px 0;

            .each-folio-grid {
                padding-bottom: 15px;
                .slider-gallery-sec {
                    display: flex;
                    gap: 10px;
                    max-width: 100%;
                    width: auto;
                    float: right;

                    .slider-gallery-box {
                        width: 200px;
                        height: 200px;
                        display: block;
                        background: #A295A5;
                        padding: 20px;

                        p {
                            color: #fff;
                            margin: 0;
                        }
                    }

                    .service-slider-box {
                        height: 200px;
                        width: calc(100% - 190px);

                        .img-gallery-wrp {
                            display: flex;
                            flex-wrap: nowrap;
                            overflow-y: auto;
                            height: 200px;
                            gap: 15px;

                            &::-webkit-scrollbar {
                                width: 5px;
                                height: 5px;
                            }

                            &::-webkit-scrollbar-track {
                                box-shadow: inset 0 0 5px transparent;
                                border-radius: 10px;
                            }

                            &::-webkit-scrollbar-thumb {
                                background: #A295A5;
                                border-radius: 10px;
                            }

                            &::-webkit-scrollbar-thumb:hover {
                                background: #A295A5;
                            }

                            span {
                                width: 200px;
                                min-width: 200px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}

.yarl__container,
.yarl__thumbnails_container,
.yarl__thumbnails_thumbnail {
    background: #312A33BF;
}
.yarl__thumbnails_vignette{
    background: none !important;
}