.header-main-wrp {
    position: relative;
    left: 0;
    top: 0;
    z-index: 99;

    .container {
        position: relative;
    }

    &.home-header {
        .head-con-sec {
            position: absolute;
            left: 0;
            top: 30px;
            padding-left: 15px;
            padding-right: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .nav-wrp {
        a {
            text-decoration: none;
        }

        h1 {
            font-family: 'Josefin Sans';
            display: flex;
            align-items: center;
            gap: 10px;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            color: #EBE8EC;
            margin: 0;
            text-align: left;

            @media (max-width: 767px) {
                & {
                    font-size: 18px;
                }
            }

            img {
                display: inline-block;
                width: 80px;
                @media (max-width: 767px) {
                    & {
                        width: 50px;
                    }
                }
            }
        }
    }

    .burger-prof-sec {
        display: flex;
        align-items: center;

        .profile-sec-box {
            width: 35px;
            margin-left: 10px;
            margin-right: 10px;
        }

        .burger-icon {
            padding: 6px;
            border-radius: 50px;
            background: rgba(245, 243, 245, 0.50);

            img {
                &.nav {
                    width: 25px;
                    height: 25px;
                }

                &.profile {
                    width: 25px;
                    height: 25px;
                }

            }
        }

        .shoping-cart-box {
            a {
                width: 30px;
                display: block;
            }
        }

        &.hide {
            display: none !important;
        }
    }
}

.menu-wrp {
    position: relative;

    .MuiPaper-root.MuiMenu-paper {
        margin-left: -25px;
        width: 100%;
        max-width: 250px;
        border-radius: 20px;
    }

    ul {
        padding-top: 25px;
        padding-bottom: 25px;

        li {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 10px;

            a {
                padding-left: 20px;
                padding-right: 15px;
                display: flex;
                align-items: center;
                gap: 10px;
                text-decoration: none;
                color: #312A33;
                font-weight: 400;
                width: 100%;

                img {
                    width: 25px;
                    height: 25px;
                    object-fit: contain;
                    object-position: center;
                }
            }
        }
    }
}