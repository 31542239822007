.siteMap-main-wrp {
    background: #F5F3F5;
    .siteMap-header-wrp{
        padding-top: 50px;
        padding-bottom: 30px;
        .siteMap-header-sec{
            h1{
                font-family: "Josefin Sans", sans-serif;
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                margin: 0;
                line-height: 1;
                position: relative;
                z-index: 9;
                color: #564959;
            }
        }
    }
    .siteMap-content-wrp{
        .each-siteMap-sec{
            .each-siteMap-list{
                padding-bottom: 35px;
                h3{
                    font-family: 'Babylonica', cursive;
                    color: #BA8381;
                    margin: 0;
                    font-size: 36px;
                    font-weight: 400;
                }
                ul{
                    padding-left: 10px;
                    li{
                        a{
                            color: #312A33;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    
    
}

.header-main-wrp.site-map-header {
    &+.content+.copy-foot {
        background: #F5F3F5 !important;
    }
}
