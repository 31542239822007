html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.3;
}

#app-root {
    overflow-x: hidden;
}

button,
a {
    cursor: pointer;
}

.f-wrp {
    position: relative;
    width: 100%;
    float: left;
    display: block;
}

img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

p {
    color: #312A33;
}

ul,
li {
    list-style: none;
    padding-left: 0;
}
a:-webkit-any-link:focus-visible {
    outline-offset: 0px;
}
:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}
.page-main-banner {
    background-image: url('../images/bannerBG.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 85px;
    min-height: 100vh;
    display: flex;
    align-items: flex-end;
}

.page-main-banner .container {
    width: 100%;
}

.section-title-foot::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 999;
    width: 100%;
    height: 155px;
    max-width: 1920px;
    right: 0;
    margin: 0 auto;
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
}

.page-main-banner::after {
    background-image: url('../images/banner-title-footer.svg');
}

.service-main-sec::after {
    background-image: url('../images/service-foot.svg');
}

.portfolio-main-sec::after {
    background-image: url('../images/portfolio-foot.svg');
}

.aboutus-main-sec::after {
    background-image: url('../images/aboutus-foot.svg');
}

.testimonial-main-sec::after {
    background-image: url('../images/testimonial-foot.svg');
}

.contact-main-sec::after {
    background-image: url('../images/contact-foot.svg');
}

.banner-content.f-wrp {
    padding-left: 0px;
    min-height: calc(100vh - 100px);
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
}

.baner-con-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 999999;
}

.banner-content h2 {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 72px;
    line-height: 0.7;
    letter-spacing: 3px;
    color: #F5F3F5;
    margin: 0;
    position: relative;
    z-index: 1;
}

.banner-content h3 {
    margin: 0;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 1;
    letter-spacing: 2px;
    color: #F5F3F5;
    margin-bottom: 35px;
}

.banner-content p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: #F5F3F5;
    max-width: 845px;
    margin: 0;
}

.banner-content p b {
    font-weight: 700;
}

.ocd-letter-sec.f-wrp {
    height: 90px;
    top: -5px;
}

.banner-content .ocd-letter {
    width: 100%;
    position: relative;
    top: -35px;
    display: flex;
    align-items: flex-end;
}

.banner-content img {
    object-fit: contain;
    object-position: right;
}

.banner-content img.little {
    width: 230px;
    display: inline-block;
    margin-right: 15px;
    position: relative;
}

.banner-content img.ocd {
    width: 335px;
    display: inline-block;
}

.sqr-btn {
    background: #FBEDE2;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.5px;
    color: #BA8381;
    width: 175px;
    height: 50px;
    padding: 15px;
    border: none;
    position: relative;
    margin-top: 65px;
    z-index: 9999;
}

.sqr-btn::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 2px solid #BA8381;
    transform: matrix(1, 0.08, -0.08, 1, 0, 0);
    transition: 0.5s all;
}

.sqr-btn:hover:after,
.sqr-btn:focus:after {
    transform: rotate(-4deg);
    transition: 0.5s all;
}

.banner-content .sqr-btn {
    margin-top: 35px;
}

.arch-img {
    /* position: absolute;
    bottom: 0;
    right: 0; */
    width: 50%;
    height: 100%;
    /* min-width: 550px;
    min-height: 550px; */
    display: flex;
    align-items: flex-end;
}

.arch-img img {
    height: calc(100% - 25px);
    object-fit: contain;
    object-position: bottom right;
}


.each-service-blk .swiper-slide {
    height: 385px !important;
    width: 100% !important;
    /* border: 2px solid #333; */
}

.each-service-blk .swiper-slide img {
    height: 100%;
    width: 100%;
    padding: 30px;
    object-fit: cover;
    object-position: center;
    position: relative;
}

.servicce-slider {
    padding: 65px;
    /* border: 2px solid #333; */
}

/* .each-service-blk .swiper-slide::after */

.servicce-slider::after,
.servicce-slider::before {
    content: '';
    position: absolute;
    left: 0;
    top: 30px;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    border-radius: 4px;
    border: 2px solid #BA8381;
    transform: matrix(1, 0.08, -0.08, 1, 0, 0);
    transition: 0.5s all;
    right: 0;
    margin: 0 auto;
    z-index: 1;
}

.servicce-slider::before {
    opacity: 0;
    width: 0%;
    height: 0%;
    z-index: 9;
    top: 50%;
}

.servicce-slider:hover:before {
    top: 30px;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    opacity: 1;
    transform: rotate(185deg);
    transition: 0.5s all;
    z-index: unset;
}

.servicce-slider:hover:after {
    /* transform: rotate(185deg); */
    /* transition: 0.5s all; */
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    opacity: 0;
    display: none;
    z-index: unset;
}

.no-padding {
    padding: 0 !important;
}
.swiper-button-next,
.swiper-button-prev{
    opacity: 0;
    transition: 0.5s all;
}
.servicce-slider:hover .swiper-button-next,
.servicce-slider:hover .swiper-button-prev{
    opacity: 1;
    transition: 0.5s all;
}
.swiper-button-next:after,
.swiper-button-prev:after {
    color: #312A33 !important;
    font-size: 9px !important;
    font-weight: bolder;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 45px) !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 45px) !important;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: var(--swiper-pagination-bottom, 50px) !important;
}

.swiper-button-next,
.swiper-button-prev {
    width: 25px !important;
    height: 25px !important;
    background: rgba(245, 243, 245, 0.85);
    border-radius: 100%;
}

.swiper-pagination-bullet-active {
    background: #564959 !important;
}

.service-slider-sec::after {
    /* content: ''; */
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 2px solid #BA8381;
    transform: matrix(1, 0.08, -0.08, 1, 0, 0);
    transition: 0.5s all;
    z-index: 1;
}

.each-service-blk.f-wrp .slick-slide img {
    width: 550px;
    height: 550px;
}

.service-main-sec {
    padding-top: 115px;
    padding-bottom: 150px;
    background: #F5F3F5;
}

.slider-con-blk {
    padding-top: 0px;
    padding-left: 30px;
    width: calc(100% - 30px);
}

.slider-con-blk h3 {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 3px;
    color: #7A687F;
    margin: 0;
}

.slider-con-blk p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #312A33;
    margin: 0;
}

.portfolio-main-sec .container-fluid {
    padding-left: 0;
    padding-right: 0;
    max-width: 1920px;
}

.portfolio-sec-row {
    display: flex;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    min-height: 100vh;
    padding: 50px 0;
}

.portfolio-sec-col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
    padding-left: 15px;
    padding-right: 15px;
}

.portfolio-sec-col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
    position: absolute;
    right: 0;
    max-width: 60%;
    height: 100%;
    overflow: hidden;
}

.portfolio-img-wrp {
    height: 100%;
}

.portfolio-img-wrp span {
    /* width: 25%; */
    flex: 1;
    transition: 0.5s all;
}

.portfolio-img-wrp span img {
    height: 100%;
}

/* .portfolio-img-wrp span:hover{
    flex: 2;
    transition: 0.5s all;
} */
.service-sec-blk h2 {
    font-family: 'Josefin Sans';
    font-weight: 700;
    font-size: 32px;
    display: flex;
    align-items: center;
    letter-spacing: 3px;
    color: #BA8381;
    margin: 0;
    margin-bottom: 50px;
}

.gap {
    height: 100px;
    background: #EBE8EC;
}

.portfolio-sec-blk>.row {
    align-items: center;
}

.portfolio-main-sec {
    background-color: #CAC3CC;
}

.portfolio-sec-blk h3 {
    font-family: 'Babylonica';
    font-weight: 400;
    font-size: 55px;
    letter-spacing: 3px;
    color: #BA8381;
    margin: 0;
}

.portfolio-sec-blk p {
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #312A33;
}

.abt-logo-content.f-wrp ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.abt-logo-content.f-wrp ul li h3 {
    font-family: 'Josefin Sans';
    font-weight: 700;
    font-size: 25px;
    letter-spacing: 3px;
    color: #BA8381;
    margin: 0;
    margin-right: 20px;
}

.abt-logo-content.f-wrp ul li span {
    width: 80px;
    height: 80px;
    display: block;
}

.abt-logo-content.f-wrp ul li span img {
    object-fit: contain;
    width: 100%;
    margin: 0 auto;
}

.abt-logo-content.f-wrp p {
    text-align: center;
    margin-top: 30px;
    font-family: 'Montserrat';
    font-weight: 300;
    line-height: 37px;
    letter-spacing: 0.5px;
    color: #312A33;
}

.abt-logo-wrp {
    padding-top: 75px;
}

.counter-blk-wrp.f-wrp {
    background: #564959;
    padding: 55px 0;
}

.counter-sec-content {
    display: flex;
    justify-content: space-around;
}

.counter-blk-wrp.f-wrp .each-counter-blk {
    text-align: center;
}

.counter-blk-wrp.f-wrp .each-counter-blk label {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    background: linear-gradient(180deg, #BA8381 0%, #FBEDE2 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.counter-blk-wrp.f-wrp .each-counter-blk p {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 3px;
    color: #F5F3F5;
    margin-bottom: 0;
}

.icon-blk-wrp.f-wrp {
    background: #A295A5;
    padding-bottom: 120px;
    padding-top: 75px;
}

.icon-sec-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.each-icon-blk.f-wrp {
    max-width: 250px;
}
.icon-sec-content .each-icon-blk span {
    display: block;
    width: 75px;
    margin: 0 auto;
}

.icon-sec-content .each-icon-blk h4 {
    text-align: center;
    font-family: 'Josefin Sans';
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 2px;
    color: #F5F3F5;
}

.icon-sec-content .each-icon-blk span img {
    object-fit: contain;
}

.testimonial-content-wrp.f-wrp {
    display: flex;
    align-items: center;
}

.middle-testi-box.f-wrp {
    padding: 0 45px;
}

.testimonial-main-sec {
    padding-bottom: 120px;
    padding-top: 75px;
}

.middle-testi-box.f-wrp h5 {
    text-align: center;
    font-family: 'Josefin Sans';
    font-weight: 700;
    font-size: 30px;
    letter-spacing: 3px;
    color: #564959;
    margin: 0;
    padding-bottom: 30px;
}

.quats {
    width: 55px;
    display: block;
}

.middle-testi-box.f-wrp b {
    font-family: 'Babylonica';
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 0.5px;
    color: #564959;
}

.right-testi-box span,
.left-testi-box span {
    display: block;
    /* height: 500px; */
}

.right-testi-box span img,
.left-testi-box span img {
    object-fit: contain;
}

/* .right-testi-box span img{
    object-position: left;
}
.left-testi-box span img{
    object-position: right;
} */

.contact-main-sec {
    padding-bottom: 115px;
    background-color: #EBE8EC;
    padding-top: 100px;
}

.contct-con-wrp {
    display: flex;
    justify-content: center;
}

.contact-content {
    text-align: center;
    background-image: url('../images/contact.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding-bottom: 35px;
}
.contact-content h4{
    margin-bottom: 5px;
}
.contact-content a,
.contact-content span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #312A33;
    text-decoration: none;
    position: relative;
    z-index: 9999;
    cursor: pointer;
}
.copy-foot .container{
    margin: 0 auto;
}

.copy-foot .container .row .col-lg-6{
    padding: 0;
}


@media screen and (max-width: 1400px) {
    .portfolio-main-sec .container-fluid {
        max-width: 1080px;
    }
}
@media screen and (max-width: 1200px) and (min-width: 991px) {
    .portfolio-sec-col-8 {
        width: 55%;
    }
    .portfolio-sec-col-4{
        width: 45%;
        padding-left: 25px;
        padding-right: 25px;
    }
    
}
@media (max-width: 992px) {
    .portfolio-main-sec .container-fluid{
        max-width: 960px;
    }
    
}
@media (max-width: 991px) {
    .ocd-letter-sec.f-wrp{
        height: auto;
    }
    .banner-content .ocd-letter{
        top: 0;
    }
    .banner-content.f-wrp{
        flex-direction: column-reverse;
        min-height: auto;
        height: auto;
        padding-top: 35px;
        padding-bottom: 115px;
    }
    .arch-img{
        position: relative;
        bottom: 0;
        right: 0;
        width: 65%;
        min-width: 100%;
        min-height: auto;
        max-width: 295px;
        margin: 0 auto;
        margin-bottom: 75px;
    }
    .arch-img {
        width: 100%;
    }
    .arch-img img{
        width: 65%;
        margin: 0 auto;
    }
    .banner-content img.little{
        width: 30%;
    }
    .banner-content img.ocd {
        width: 65%;
    }
    .banner-content p{
        font-size: 15px;
    }
    .portfolio-main-sec .container-fluid{
        max-width: 100%;
    }
    .portfolio-sec-row{
        flex-direction: column;
        padding-top: 50px;
        padding-bottom: 10px;
    }
    .portfolio-sec-col-4{
        padding-bottom: 50px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }
    .portfolio-sec-col-8{
        position: relative;
        width: 100%;
        max-width: 100%;
    }
    .middle-testi-box.f-wrp{
        padding-top: 350px;
    }
    .testimonial-content-wrp.f-wrp .left-testi-box,
    .testimonial-content-wrp.f-wrp .right-testi-box{
        width: 50%;
        position: absolute;
        left: 0;
        height: 300px;
        top: 0;
    }
    .testimonial-content-wrp.f-wrp .right-testi-box{
        right: 0;
        left: unset;
    }
    .testimonial-content-wrp.f-wrp .left-testi-box span{ 
        height: 100%;
        padding-right: 5px;
    }
    .testimonial-content-wrp.f-wrp .right-testi-box span{
        height: 100%;
        padding-left: 5px;
    }
    .testimonial-content-wrp.f-wrp .left-testi-box span img{ 
        object-position: right;
    }
    .testimonial-content-wrp.f-wrp .right-testi-box span img{
        object-position: left;
    }
    .service-slider-sec.f-wrp .swiper-wrapper{
        z-index: 9;
    }
}
@media (max-width: 767px) {
    .each-service-blk{
        margin-bottom: 55px;
    }
    .counter-sec-content{
        flex-direction: column;
    }
    .counter-blk-wrp.f-wrp .each-counter-blk:not(:last-child){
        margin-bottom: 50px;
    }
}
@media (max-width: 600px) {
    .banner-content.f-wrp{
        padding-top: 10px;
    }
    .arch-img {
        width: 100%;
        margin-bottom: 35px;
    }
    .arch-img img{
        /* width: 65%;
        margin: 0 auto; */
        width: 100%;
        margin: 0 auto;
        height: calc(100vh - 375px);
        object-position: center;
    }
    .banner-content img.little {
        width: 35%;
    }
    .banner-content img.ocd {
        width: 60%;
    }
    .banner-content h2{
        font-size: 50px;
    }
    .banner-content h3{
        font-size: 40px;
    }
}
@media (max-width: 350px) {
    .banner-content h2 {
        font-size: 30px;
    }
    .banner-content h3 {
        font-size: 28px;
    }
    .arch-img img{
        height: calc(100vh - 295px);
    }
    .banner-content img.little {
        width: 35%;
    }
    .banner-content img.ocd {
        width: 50%;
    }
}



.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}
.fade.modal.show, .fade.modal.show {
    background: rgba(0, 0, 0, 0.8);
    z-index: 99999999;
}
.modal-dialog {
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
    background: #fff;
    margin-top: 34px;
}
.modal-body{
    position: relative;
}
.modal-body button{
    position: absolute;
    right: 35px;
    top: 30px;
    background: transparent;
    border: none;
    padding: 0;
}